<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import Tablevideo from '@components/video/tablevideo'
import Tablefighter from '@components/fighter/tablefighter'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: '',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Tablevideo,
    Tablefighter,
    //  PageHeader
  },
  data() {
    return {
      data: [],
    }
  },
  mounted() {
    this.onLode()
  },
  methods: {
    async onLode() {
      await axios
        .get(`/api/category/sub/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data
          // console.log(this.data)
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
    async handleClickEdit() {
      this.$router.push({
        path: `/category/child/sub/form/${this.$route.params.id}`,
        query: { id: this.$route.params.id },
      })
    },
    async handleClickForm() {
      this.$router.push({
        path: `/category/child/sub/form/${this.$route.params.id}`,
        // query: { id: this.$route.params.id },
      })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 v-if="this.data.category_name" class="mb-1 mt-0">{{
          this.data.category_name
        }}</h4>
      </div>
      <div class="col-xl-6">
        <router-link
          :to="`/category/child/sub/create/${this.$route.params.id}`"
        >
          <button
            v-if="data.is_fighter !== true"
            class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          >
            <feather type="file-plus"></feather>Create Video
          </button>
        </router-link>
        <!-- 
        <button
          v-if="data.is_fighter == undefined"
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="handleClickEdit()"
        >
          <feather type="file-plus"></feather>Add Fighter
        </button> -->

        <button
          v-if="data.is_fighter == true || data.is_fighter == undefined"
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="handleClickForm()"
        >
          <feather type="file-plus"></feather>Add Fighter
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <Tablevideo
            v-if="data.is_fighter == false"
            :sub_id="this.$route.params.id"
          >
          </Tablevideo>
          <Tablefighter v-if="data.is_fighter == true"></Tablefighter>
        </div>
      </div>
    </div>
  </Layout>
</template>
